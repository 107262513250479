import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenStorage {
	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('accessToken');
		return of(token);
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('refreshToken');
		return of(token);
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		const roles: any = localStorage.getItem('userRoles');
		try {
			return of(JSON.parse(roles));
		} catch (e) {}
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	 public getPathPermission(): Observable<any> {
		const paths: any = localStorage.getItem('pathPermission');
		try {
			return of(JSON.parse(paths));
		} catch (e) {}
	}

	/**
	 * Get user menu in JSON string
	 * @returns {Observable<any>}
	 */
	 public getUserMenu(): Observable<any[]> {
		const userMenu: any = localStorage.getItem('userMenu');
		try {
			return of(JSON.parse(userMenu));
		} catch (e) {}
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		localStorage.setItem('accessToken', token);

		return this;
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(token: string): TokenStorage {
		localStorage.setItem('refreshToken', token);

		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		if (roles != null) {
			localStorage.setItem('userRoles', JSON.stringify(roles));
		}

		localStorage.setItem('userRoles', JSON.stringify(["ADMIN"]));
		return this;
	}

	/**
	 * Set path permissions
	 * @param paths
	 * @returns {TokenStorage}
	 */
	 public setPathPermission(paths: any): any {
		if (paths != null) {
			localStorage.setItem('pathPermission', JSON.stringify(paths));
		}

		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	 public setUserMenu(userMenu: any): any {
		if (userMenu != null) {
			localStorage.setItem('userMenu', JSON.stringify(userMenu));
		}
		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userRoles');
	}
}
