
export const environment = {
    production: false,
    authSettings: {
        client_id: 'FretterApi',
        client_secret: '4531a3f2b670e434344345718aa8c14976',
        scope: 'portal offline_access',
        grant_type: 'password',
        grant_type_refreshToken: 'refresh_token'
    }
};
