import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvioConfiguracao } from '../models/Fusion/envioConfiguracao.model';
import { BaseService } from './base.service';

@Injectable()
export class EnvioConfiguracaoService extends BaseService {
	constructor(){
		super('/api/EnvioConfiguracao/')
	}
	
	public getListaEnvioConfiguracaoPorIdEmpresa(empresaIntegracaoId : number): Observable<any> {
		const url = `GetListaEnvioConfiguracaoPorIdEmpresa/${empresaIntegracaoId}`;
		return this._http.get(`${this._config.baseUrl}${this._api}${url}`);
	}
}
