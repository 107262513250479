import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { EmpresaIntegracaoItem } from '../models/Fusion/empresaIntegracaoItem.model';
import { EmpresaIntegracaoItemFiltro } from '../models/Fusion/empresaIntegracaoItemFiltro.model';
@Injectable()
export class EmpresaIntegracaoItemService extends BaseService {
	constructor(){
		super('/api/EmpresaIntegracaoItem/')
	}
	
	public getEmpresaIntegracaoItem(model : EmpresaIntegracaoItemFiltro): Observable<any> {
		let queryString = super.getQueryString(model);
		return this._http.get(`${this._config.baseUrl}${this._api}filter?${queryString}`);
	}
	
	public getEmpresaIntegracaoItemPorEmpresa(empresaIntegracaoId : number): Observable<any> {
		const url = `obterPorEmpresaIntegracaoItem/${empresaIntegracaoId}`;
		return this._http.get(`${this._config.baseUrl}${this._api}${url}`);
	}

	public getParametrosLayout(dsProcedure : string): Observable<any> {
		const url = `ObterParametrosLayout/${dsProcedure}`;
		return this._http.get(`${this._config.baseUrl}${this._api}${url}`);
	}

	public putVoltarVersao(empresaIntegracaoItemHistorico: number): Observable<any> {

		const url = `VoltarVersaoAnteriorPorIdEmpresaItemHistorico/${empresaIntegracaoItemHistorico}`;
		return this._http.get(`${this._config.baseUrl}${this._api}${url}`);
	}

	public getHistoricosPorIdEmpresaIntegracaoItem(empresaIntegracaoId : number): Observable<any> {
		const url = `ObterTodosHistoricosPorIdEmpresaIntegracaoItem/${empresaIntegracaoId}`;
		return this._http.get(`${this._config.baseUrl}${this._api}${url}`);
	}

}
