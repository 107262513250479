import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export class EmpresaTransporteConfiguracaoItemService extends BaseService {
	constructor(){
		super('/api/empresaTransporteConfiguracaoItem/')
	}

	public getServicosAdicionaisPorCategoria(categoria : string, codigoConfiguracaoItem: number): Observable<any> {
		const url = `obterServicosAdicionaisPorCategoria/${categoria}/${codigoConfiguracaoItem}`;
		return this._http.get(`${this._config.baseUrl}${this._api}${url}`);
	}

	public postSalvarServicoAdicional(input : any): Observable<any> {
		const url = `salvarServicoAdicional`;
		return this._http.post(`${this._config.baseUrl}${this._api}${url}`, input);
	}
}
