import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-top-clientes',
  templateUrl: './top-clientes.component.html',
  styleUrls: ['./top-clientes.component.scss']
})
export class TopClientesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
