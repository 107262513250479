import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmpresaIntegracaoTipo } from '../models/Fusion/empresaIntegracaoTipo.model';
import { BaseService } from './base.service';

@Injectable()
export class EmpresaIntegracaoTipoService extends BaseService {
	constructor(){
		super('/api/EmpresaIntegracaoTipo/')
	}

}
