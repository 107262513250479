import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmpresaIntegracaoConfiguracao } from '../models/Fusion/empresaIntegracaoConfiguracao.model';
import { BaseService } from './base.service';

@Injectable()
export class EmpresaIntegracaoConfiguracaoService extends BaseService {
	constructor(){
		super('/api/EmpresaIntegracaoConfiguracao/')
	}

}
